@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Outfit:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  box-sizing: border-box;
  overflow-x: hidden;
}

:root {
  background-color: #ffffff;
  color: #000000;
}

* {
  font-family: "Outfit", sans-serif;
}

@layer utilities {
  .wrapper {
    @apply max-w-[90rem] mx-auto px-4 sm:px-6 lg:px-8;
  }
  .heading-1 {
    @apply text-4xl tracking-widest leading-tight;
  }
  .heading-2 {
    @apply text-3xl tracking-widest leading-tight;
  }
  .desc {
    @apply font-light tracking-wide leading-tight;
  }
  .btn {
    @apply text-[.8rem] px-6 py-3 uppercase tracking-widest hover:-translate-y-1 transition-all duration-300;
  }
  .primary-btn {
    @apply text-black shadow-large shadow-transparent hover:shadow-primary/30 border-x border-y-2 border-primary bg-gradient-to-b from-[#fff7e6] to-[#956520] hover:from-[#e5cdab] hover:to-[#956520];
  }
}

::-webkit-scrollbar {
  scroll-behavior: smooth;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  @apply bg-primary;
  border-radius: 8px;
}

/* swiper styles */
.swiper-pagination-bullet-active {
  background-color: #cba85c !important;
}

/* loading spinner styles */
.spinner-parent {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.726);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
}

.loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #fff;
}
.loader:before,
.loader:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}
.loader:after {
  color: #CBA85C;
  transform: rotateY(70deg);
  animation-delay: 0.4s;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: 0.2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: 0.2em 0.2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 0.2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -0.2em 0.2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -0.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -0.2em -0.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -0.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: 0.2em -0.2em 0 0 currentcolor;
  }
}

.animate_color {
  animation: animateColor 2s linear infinite;
}

@keyframes animateColor {
  0% {
    color: #119814;
  }
  50% {
    color: #c99b37;
  }
  100% {
    color: #119814;
  }
}
